const size = {
	mobileSmall: '350px',
	mobile: '480px',
	tablet: '768px',
	desktop: '1024px',
	desktopHd: '1200px',
	desktopHhd: '1480px',
	desktopFhd: '1915px',
};

export const device = {
	mobileSmall: `(max-width: ${size.mobileSmall})`,
	mobile: `(max-width: ${size.mobile})`,
	tablet: `(max-width: ${size.tablet})`,
	desktop: `(max-width: ${size.desktop})`,
	desktopHd: `(max-width: ${size.desktopHd})`,
	desktopHhd: `(max-width: ${size.desktopHhd})`,
	desktopFhd: `(max-width: ${size.desktopFhd})`,
};
