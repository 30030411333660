import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Logo from './svg/Logo';
import IconMenu from './svg/IconMenu';
import { device } from '../lib/device';
import MenuItems from '../hooks/getMenuItems';

const HeaderWrap = styled.header`
	display: grid;
	grid-template-columns: 1fr auto;
	border-bottom: 1px solid var(--brand_blue);
	padding: 9px 50px;
	box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.15);
	@media ${device.desktop} {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1000;
		padding: 9px 15px;
		background-color: white;
	}
	.logo {
		width: 130px;
		height: 47px;
		svg {
			position: relative;
		}
	}
	.mobile_menu_trigger {
		display: none;
		border: none;
		outline: none;
		background-color: transparent;
		svg {
			width: 30px;
			height: 30px;
		}
		@media ${device.desktop} {
			display: block;
		}
	}
	.main_menu {
		display: flex;
		align-self: center;
		padding: 0;
		margin: 0;
		@media ${device.desktop} {
			display: none;
			position: absolute;
			top: 71px;
			background-color: white;
			width: 100%;
			right: 0;
			padding: 15px;
			z-index: 1000;
			box-shadow: -5px 5px 20px 2px rgba(0, 0, 0, 0.36);
			max-width: 500px;
			border-left: 1px solid var(--brand_blue);
			border-bottom: 1px solid var(--brand_blue);
			&.is_active {
				display: block;
			}
		}
		li {
			list-style-type: none;
			margin-right: 35px;
			position: relative;
			@media ${device.desktop} {
				margin: 0;
				margin-bottom: 20px;
				text-align: right;
			}
			a {
				font-family: var(--font_harmoniasanspro_light);
				color: var(--brand_black);
				display: block;
				font-weight: 300;
				font-size: 20px;
				text-decoration: none;
				transition: all 0.4s ease;
				&:hover,
				&.is_active {
					transition: all 0.4s ease;
					color: var(--brand_blue);
					&:after {
						display: block;
						position: absolute;
						left: 0;
						bottom: -21px;
						background-color: var(--brand_blue);
						width: 100%;
						height: 4px;
						content: ' ';
						@media ${device.desktop} {
							bottom: -5px;
							height: 2px;
							width: 100px;
							left: auto;
							right: 0;
						}
					}
				}
			}
		}
	}
`;

const Header = () => {
	const [mobileActive, setMobileActive] = useState(false);
	const data = MenuItems();
	return (
		<HeaderWrap>
			<Link to="/" className="logo">
				<Logo />
			</Link>
			<button type="button" className="mobile_menu_trigger" onClick={() => setMobileActive(!mobileActive)}>
				<IconMenu />
			</button>
			<ul className={`main_menu ${mobileActive ? 'is_active' : ''}`}>
				{data.menuItems.nodes.map((item) => (
					<li key={item.id}>
						<Link activeClassName="is_active" to={item.url}>
							{item.label}
						</Link>
					</li>
				))}
			</ul>
		</HeaderWrap>
	);
};

export default Header;
