import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const MenuItems = () => {
	const data = useStaticQuery(graphql`
		{
			wpMenu {
				menuItems {
					nodes {
						id
						url
						label
					}
				}
			}
		}
	`);
	return data.wpMenu;
};

export default MenuItems;
